<template>
    <div class="mx-4 my-4 md:mx-8">
        <Breadcrumb :links="breadcrumb" />

        <form @submit.prevent="start" class="grid grid-cols-12 gap-4 my-10 items-center">
            <div class="col-span-12 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                <label class="block text-sm font-medium">De</label>
                <input type="date" class="mt-1 w-full rounded-md text-center border-gray-300" :value="form.de"
                    @input="(event) => form.de = event.target.value" />
            </div>
            <div class="col-span-12 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                <label class="block text-sm font-medium">Até</label>
                <input type="date" class="mt-1 w-full rounded-md text-center border-gray-300" :value="form.ate"
                    @input="(event) => form.ate = event.target.value" />
            </div>
            <div class="col-span-12 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                <label class="block text-sm font-medium">Operador</label>
                <v-select v-model="form.operador" :options="operadores" label="nome" :reduce="(p) => p._id" name="operador" id="operador" class="mt-1"></v-select>
            </div>
            <div class="col-span-12 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                <button type="submit" class="w-full sm:mt-5 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 
                    focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm 
                    hover:shadow-md w-full font-semibold text-center">
                    Buscar
                </button>
            </div>
        </form>
        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <h1 class="text-center text-base text-gray-500 font-semibold">
                    Essa tabela mostra <span class="font-bold text-gray-700">APENAS</span> as conversas finalizadas pelo
                    botão "Finalizar conversas".
                </h1>
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200" v-if="logsDosChats.length > 0">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Nome
                                    </th>
                                    <th scope="col"
                                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Email
                                    </th>
                                    <th scope="col"
                                        class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Data
                                    </th>
                                    <th scope="col"
                                        class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Quantidade de conversas encerradas
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(log, index) in logsDosChats" :key="index">
                                    <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                                        {{ log.nome }}
                                    </td>
                                    <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                                        {{ log.email }}
                                    </td>
                                    <td class="text-sm text-center 2xl:text-base px-2 py-3 whitespace-nowrap">
                                        {{ log.data }}
                                    </td>
                                    <td class="text-sm text-center 2xl:text-base px-2 py-3 whitespace-nowrap">
                                        {{ log.quantidade }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p v-else class="p-2 my-4 text-center font-semibold text-zinc-500">Desculpe, sem resultados {{ ':(' }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";
import moment from "moment";

export default ({
    components: {
        Breadcrumb,
    },
    data() {
        return {
            breadcrumb: [
                { url: "/relatorios/relatorio-chats-finalizados", value: "Relatório chats finalizados" },
            ],
            logsDosChats: [],
            operadores: [],
            form: {
                de: moment().startOf("M").format("YYYY-MM-DD"),
                ate: moment().endOf("M").format("YYYY-MM-DD"),
                operador: null
            }
        }
    },
    methods: {
        async start() {
            const de = moment(this.form.de).format("DD/MM/YYYY");
            const ate = moment(this.form.ate).format("DD/MM/YYYY");
            const operador = this.form.operador;

            const URL = `/v1/chatv2/conversas-encerradas?de=${de}&ate=${ate}&operador=${operador}`;
            const response = await this.$http.get(URL);

            if (!response.data.success) {
                return this.$vToastify.error(response.data.err);
            }

            this.logsDosChats = response.data.logsDeConversas;
        },

        async buscaOperadores() {
            const operadoresReq = await this.$http.post("/v1/operador/list", { all: true, admin: true });
            this.operadores = operadoresReq.data.data;
        }
    },

    async beforeMount() {
        await this.start();
        await this.buscaOperadores();
    },
});

</script>